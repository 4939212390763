header {
  padding: 2rem 1rem;
}

header h1 {
  text-indent: 50px;
  font-size: 1.4rem;
  line-height: 2rem;
}

header h1:before {
  content: "";
  width: 30px;
  height: 30px;
  background: url("logo.2fdd9df6.webp") 0 0 / cover;
  position: absolute;
  left: .5em;
}

header nav {
  gap: .5rem;
}

header nav a {
  font-size: 1.2rem;
}

#vision h2 {
  margin-left: 0;
  font-size: 2rem;
}

#vision h2 span {
  font-size: 3rem;
}

#vision .boxes .dark, #vision .boxes .light {
  font-size: 1rem;
}

#vision .boxes .light {
  left: 0;
}

/*# sourceMappingURL=index.09748e0f.css.map */
